.Footer {
	max-width: 320px;
	margin-left: auto;
	margin-right: auto;

	margin-top: 32px;
	padding-top: 24px;

	border-top: 2px solid darken(#f4f6fa, 5%);

	a {
		color: #000;
		font-size: 12px;
		border-right: 1px solid darken(#f4f6fa, 5%);
		padding-right: 8px;
		margin-right: 8px;
	}

	small {
		font-size: 12px;
	}
}
