.Input {
	border: none;
	background-image: none;
	background-color: transparent;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	box-sizing: border-box;

	display: block;
	margin-bottom: 16px;
	width: 100%;
	border-radius: 8px;
	border: 1px solid #F3F4F7;
	padding: 16px;
	background-color: #fff;
	font-family: "Roboto", sans-serif;
	font-size: 16px;

	box-shadow: inset -1px 2px 4px 0px rgba(0,0,0,0.2);

	&:focus {
		border-color: var(--color-primary);
		outline: none;
	}
}

.Label {
	display: block;
	margin-bottom: 4px;
	font-weight: bold;
	color: var(--color-primary);
}
