@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap');

body {
	background-color: #F3F4F7;
	color: var(--color-font);
	font-family: "Roboto", sans-serif;
	font-size: 16px;
}

h1 {
	font-weight: bold;
	font-size: 23px;
	line-height: 28px;
	margin-bottom: 24px;
}

p {
	margin-top: 0;
	margin-bottom: 24px;
}
