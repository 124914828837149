.Button {
	box-shadow: 0px 0px 0px transparent;
	border: 0px solid transparent;
	text-shadow: 0px 0px 0px transparent;

	border-radius: 8px;
	background-color: var(--color-secondary);
	color: var(--color-font);
	font-weight: bold;
	text-align: center;
	display: block;
	width: 100%;
	padding: 16px;
	cursor: pointer;
	font-family: "Roboto", sans-serif;
	font-size: 16px;
	margin-top: 32px;
}
