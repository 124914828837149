@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,700;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Page_Page__3evaB .Page_Content__1LSxg{max-width:320px;margin-left:auto;margin-right:auto}

.Header_Header__1CTzX{background-color:#ffffff;box-shadow:2px 2px 7px 0 rgba(0,0,0,0.05);text-align:center;margin-bottom:32px}.Header_Header__1CTzX .Header_Logo__3cTtT{height:30px;padding-top:16px;padding-bottom:16px;vertical-align:middle}

.Footer_Footer__37hEc{max-width:320px;margin-left:auto;margin-right:auto;margin-top:32px;padding-top:24px;border-top:2px solid #e2e8f2}.Footer_Footer__37hEc a{color:#000;font-size:12px;border-right:1px solid #e2e8f2;padding-right:8px;margin-right:8px}.Footer_Footer__37hEc small{font-size:12px}

.Button_Button__2BYYQ{box-shadow:0px 0px 0px transparent;border:0px solid transparent;text-shadow:0px 0px 0px transparent;border-radius:8px;background-color:var(--color-secondary);color:var(--color-font);font-weight:bold;text-align:center;display:block;width:100%;padding:16px;cursor:pointer;font-family:"Roboto", sans-serif;font-size:16px;margin-top:32px}

.Title_Title__2JDeu{font-weight:bold;font-size:23px;line-height:28px;color:var(--color-primary);margin-bottom:24px}

.Paragraph_Paragraph__2rah-{margin-top:0;margin-bottom:24px}

.Input_Input__2mPn8{border:none;background-image:none;background-color:transparent;box-shadow:none;box-sizing:border-box;display:block;margin-bottom:16px;width:100%;border-radius:8px;border:1px solid #F3F4F7;padding:16px;background-color:#fff;font-family:"Roboto", sans-serif;font-size:16px;box-shadow:inset -1px 2px 4px 0px rgba(0,0,0,0.2)}.Input_Input__2mPn8:focus{border-color:var(--color-primary);outline:none}.Input_Label__3Rad7{display:block;margin-bottom:4px;font-weight:bold;color:var(--color-primary)}

.Error_Error__2d0Nr{color:red;margin-top:24px}

body{background-color:#F3F4F7;color:var(--color-font);font-family:"Roboto", sans-serif;font-size:16px}h1{font-weight:bold;font-size:23px;line-height:28px;margin-bottom:24px}p{margin-top:0;margin-bottom:24px}

